
export default {
  name: 'ThemecontainerBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      if (this.blok.theme === 'dark') {
        return "background: black"
      }
      return {}
    },
  },
}
